.databaseContainer {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);  */
    border-radius: 5px; 
    /* color: white; */
    /* border: 2px solid white; */
    width: 60vw; 
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.databaseContainer h3{
    font-size: 1.5em;
}

.databaseContainer button{
    margin: 20px;
}

.databaseTable th {
    padding: 10px 15px;
    background-color: #336ca6;
    border-radius: 10px;
    text-align: center;
    white-space: nowrap;
    color: white;
}

.databaseTable td {
    border-spacing: 10px 10px;
    border: none;
    padding-left: 10px;
    text-align: center;
    border: 1.5px solid #336ca6;
    border-radius: 6px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.databaseTable button#participantButton {
    background-color: #ffffff;
    color:  rgb(17, 30, 53);
}

.databaseTable button#participantButton:hover {
    text-decoration: underline;
}

.searchBarContainer {
    margin-bottom: 20px;
  }

.databaseTable th:nth-child(1) { width: 20%; font-weight: bolder; }
.databaseTable th:nth-child(2) { width: 20%; }
.databaseTable th:nth-child(3) { width: 20%; }
.databaseTable th:nth-child(4) { width: 20%; }
.databaseTable th:nth-child(5) { width: 20%; }