.settings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    align-items: stretch;
}


.form-section {
    flex: 1 1 calc(50% - 20px);
    min-width: 250px;
    max-width: calc(50% - 20px);
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    box-sizing: border-box;
}

.theme-selector {
    margin-bottom: 1rem;
}

.theme-selector label,
.form-section label {
    display: block;
    margin-top: 1rem;
    color: #495057;
    font-size: 1rem;
}

.form-section input[type="text"],
.form-section input[type="password"],
.form-section select{
    width: 100%;
    padding: 0.3rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box; 
}

.form-section h2 {
    margin-bottom: 1rem;
    color: #007bff;
    font-size: 1.5rem;
}

.setting-submit-btn {
    background-color: #007bff;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    margin-top: 1rem;
}

.setting-submit-btn:hover {
    background-color: #0056b3;
}

.setting-submit-btn:active {
    background-color: #004085;
}

.light-theme .form-section {
    background-color: rgb(245, 245, 245);
    color: rgb(17, 30, 53);
}

.dark-theme .form-section {
    background-color: rgb(17, 30, 53);
    color: rgb(245, 245, 245); 
}

.dark-theme .form-section label {
    color: rgb(245, 245, 245); 
}

@media (max-width: 600px) {
    .form-section {
        max-width: 100%; 
    }
}