.mainContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
}
  
.titleContainer {
    display: flex;
    flex-direction: column;
    font-size: 4rem; 
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: 40px;
}
  
.resultContainer,
.historyItem {
    flex-direction: row;
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-between;
}

.historyContainer {
    flex-direction: column;
    display: flex;
    height: 200px;
    align-items: center;
    flex-grow: 5;
    justify-content: flex-start;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
}
  
.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
  
.inputBox {
    height: 48px;
    width: 400px;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid grey;
    padding-left: 8px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px; 
}
  
button {
    background: #007bff; 
    color: white; 
    padding: 12px 24px;
    font-size: 1.125rem; 
    border: none; 
    border-radius: 8px; 
    cursor: pointer; 
    outline: none; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out; 
}
  
login-submit-btn:hover {
    background: #0056b3; 
    transform: scale(1.05); 
}

login-submit-btn:active {
    background: #004085; 
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3); 
}

.light-theme .titleContainer {
    color: rgb(17, 30, 53); 
}

.dark-theme .titleContainer {
    color: #fff;
}

  