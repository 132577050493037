.mainContainerStudy {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
    padding: 70 70;
}

.titleContainerStudy {
    display: flex;
    flex-direction: column;
    font-size: 48px;
    font-weight: bolder;
    align-items: flex-start;
    justify-content: flex-start;
}
.tableAndButtons {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.databaseContainer {
    flex: 0 0 80%;
    overflow-y: auto;
    max-height: 50vh;    
}

.home-buttons {
    flex: 0 0 20%;
    flex-basis: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 20px;
    gap: 10px;
    height: fit-content;
    width: auto;
    position: sticky;
    top: 0;
}

.home-buttons button {
    background-color: #336ca6;
    font-weight: bold;
  }

.home-buttons button:hover {
    text-decoration: underline;
}

.databaseTable thead th {
    position: sticky;
    top: 0;
}

@media (max-width: 1080px) {
    .tableAndButtons {
        flex-direction: column;
    }

    .home-buttons {
        order: 1;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        padding: 0;
        margin-bottom: 20px;
      }
    
      .home-buttons button {
        width: 100%;
      }

    .searchBarContainer {
        order: 0;
    }

    .databaseContainer {
        order: 2;
    }
}