#participantSearch {
    height: 40px !important;
    width: 400px;
    font-size: large;
    border-radius: 8px;
    border: 1px solid grey;
    padding-left: 8px;
    position: sticky;
    flex-direction: column;
}

