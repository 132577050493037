.card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: calc(90vh - 56px);
    margin: 5vh auto;
    background-color: rgb(137, 174, 238);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: white;
    border: 2px solid white;
}

body {
    margin: 0;
    color: white;
    background-color: rgb(17, 30, 53);
}

.card.light-theme {
    border: 1px solid rgb(17, 30, 53);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
}

.body.dark-theme {
    background-color: rgb(17, 30, 53);
}

.card.dark-theme {
    border: 1px solid white;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    background-color: rgb(40, 53, 76);
}

.card-body {
    overflow: auto; 
    max-width: 100%;
}
