.small-font {
  font-size: 12px;
}

.medium-font {
  font-size: 16px; 
}

.large-font {
  font-size: 20px;
}
