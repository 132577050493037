.entryTableContainer {
    width: 80%; 
    max-height: 60vh; 
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.entryTableContainer h3{
    font-size: 1.5em;
}

.entryTableContainer button{
    margin: 20px;
}

.entryTable th {
    border-radius: 10px;
    text-align: center;
    white-space: nowrap;
    
    position: sticky;
    top: 0;
    text-align: center;
    width: 20%;
    padding: 10px 15px;
    margin: 10px 10px;
    outline: none;
  
    font-size: 1em;
    color: #ffffff;
    background-color: #336ca6;
    border: none;
    white-space: nowrap;
}

.entryTable td {
    border-spacing: 10px 10px;
    border: none;
    padding: 12px 12px;
    text-align: center;

    border: 1.5px solid #336ca6;
    border-radius: 6px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.entryTable button#participantButton {
    background-color: #ffffff;
    color:  rgb(17, 30, 53);
}

.entryTable button#participantButton:hover {
    text-decoration: underline;
}

